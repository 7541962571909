import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IndexComponent } from './index/index.component';
import { DominiosComponent } from './dominios/dominios.component';
import { EmailComponent } from './email/email.component';
import { PublicVpsComponent } from './public-vps/public-vps.component';
import { BackupSoftwareComponent } from './backup-software/backup-software.component';
import { ContactarComponent } from './contactar/contactar.component';
import { MyProjectsComponent } from './my-projects/my-projects.component';
import { ConectividadComponent } from './conectividad/conectividad.component';
import { RegistroComponent } from './registro/registro.component';
import { TerminosComponent } from './terminos/terminos.component';

const routes: Routes = [
  { path: '', loadComponent: () => import('./index/index.component').then(c=>c.IndexComponent) },
  { path: 'dominios', loadComponent: () => import('./dominios/dominios.component').then(c=>c.DominiosComponent)},
  { path: 'email', loadComponent: () => import('./email/email.component').then(c=>c.EmailComponent)},
  { path: 'vps', loadComponent: () => import('./public-vps/public-vps.component').then(c=>c.PublicVpsComponent)},
  { path: 'backup', loadComponent: () => import('./backup-software/backup-software.component').then(c=>c.BackupSoftwareComponent)},
  { path: 'contactar', loadComponent: () => import('./contactar/contactar.component').then(c=>c.ContactarComponent)},
  { path: 'proyectos', loadComponent: () => import('./my-projects/my-projects.component').then(c=>c.MyProjectsComponent)},
  { path: 'conectividad', loadComponent: () => import('./conectividad/conectividad.component').then(c=>c.ConectividadComponent)},
  { path: 'registro', loadComponent: () => import('./registro/registro.component').then(c=>c.RegistroComponent)},
  { path: 'terminos', loadComponent: () => import('./terminos/terminos.component').then(c=>c.TerminosComponent)},
  { path: 'forgot', loadComponent: () => import('./forgot-password/forgot-password.component').then(c=>c.ForgotPasswordComponent)},
  { path: 'change/:hashData', loadComponent: () => import('./change-password/change-password.component').then(c=>c.ChangePasswordComponent)},
  { path: 'activar/:hashData', loadComponent: () => import('./activar-cuenta/activar-cuenta.component').then(c=>c.ActivarCuentaComponent)}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WebpageRoutingModule { }
